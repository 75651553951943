
import { onMounted } from 'vue'
export default {
  props: {
    navBarItem: {
      type: Object,
      required: true
    }
  },
  setup() {
    const adjustDropdown = () => {
      const dropdowns = document.getElementsByClassName('drop-menu')
      for (const dropdown of dropdowns) {
        dropdown.setAttribute('style', '')
        const rect = dropdown.getBoundingClientRect()
        const right = rect.right
        const windowWidth = window.innerWidth
        if (right > windowWidth) {
          dropdown.setAttribute('style', `left: ${windowWidth - right}px`)
        }
      }
    }
    onMounted(() => {
      adjustDropdown()
      window.addEventListener('resize', adjustDropdown)
    })
  }
}
