<template>
  <nav
    id="nav-menu-container" 
  >
    <div 
      class="nav-menu"
      :class="isOpen ? 'visible' : 'offscreen-left invisible'"
    >
      <div class="nav-menu-header d-flex d-lg-none">
        <a
          class="px-3"
          href="https://barbri.com"
        >
          <img
            :src="require('~/img/barbri_logo_black.svg')"
            class="logo align-baseline float-start"
            alt=""
          >
        </a>
        <button
          id="close-btn"
          class="px-3"
          type="button" 
          aria-label="Close Nav"
          @click="closeMega"
        >
          <div class="">
            ⨉
          </div>
        </button>
      </div>
      <div
        v-for="menu in menus"
        :key="menu.value"
      >
        <button
          :class="['mega-menu-btn p-3', {'mega-active': menuNumber == menu.value, 'mega-inactive': menuNumber != menu.value}]"
          :aria-controls="menu.htmlId"
          :aria-expanded="menuNumber == menu.value"
          @click="onMenuOptionClicked(menu.value)"
        >
          {{ menu.name }}
          <img
            :src="require('~/img/chevron_right.svg')"
            class="arrow-svg my-auto"
            alt=""
          >
        </button>
      </div>
      <a
        class="mega-menu-btn p-3 mb-2 text-decoration-none "
        href="https://barbri.com/law-firms-organizations-programs"
      >
        For Law Firms + Organizations
      </a>
      <a
        class="mobile-small-link"
        href="https://barbri.com/resources"
      >
        Resources
      </a>
      <a
        class="mobile-small-link"
        href="https://barbri.com/about-barbri"
      >
        About Us
      </a>
      <a
        class="mobile-small-link"
        href="https://barbri.com/contact-us"
      >
        Contact Us
      </a>
  
      <div
        v-for="menu in menus"
        :key="menu.value"
      >
        <MobileNavBarItem
          :is-open="menuNumber == menu.value"
          :nav-bar-item="menu.menuItem"
          @close-mega="closeMega"
          @close-menu="closeMenu"
        />
      </div>
    </div>
  </nav>
</template>

<script>
import { ref } from 'vue'
import MobileNavBarItem from '../NavigationBar/MobileNavBarItem.vue'
import IndividualLearnersMenuItem from '../NavigationBar/IndividualLearnersMenu.json'
import LawSchoolsMenuItem from '../NavigationBar/LawschoolsUnisMenu.json'

export default {
  components: {MobileNavBarItem},
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  setup(_, { emit }) {
    let menuNumber = ref(0)
    const menus = [
      { value: 1, name: IndividualLearnersMenuItem.title, htmlId: IndividualLearnersMenuItem.htmlId + '-mobile', menuItem: IndividualLearnersMenuItem },
      { value: 2, name: LawSchoolsMenuItem.title, htmlId: LawSchoolsMenuItem.htmlId + '-mobile', menuItem: LawSchoolsMenuItem }
    ]

    const onMenuOptionClicked = (menuOption) => {
      if (menuNumber.value == menuOption) {
        menuNumber.value = 0
      } else {
        menuNumber.value = menuOption
      }
    }

    const onMenuOptionHovered = (menuOption) => {
      if (window.innerWidth > 992) {
        menuNumber.value = menuOption
      }
    }

    const closeMega = () => {
      emit('close')
    }

    const closeMenu = () => {
      menuNumber.value = 0
    }

    return {
      menuNumber,
      menus,
      onMenuOptionClicked,
      onMenuOptionHovered,
      closeMega,
      closeMenu,
      MobileNavBarItem
    }
  }
}
</script>

<style scoped>
.nav-menu {
  position: absolute;
  right: auto;
  display: block;
  float: left;
  flex-direction: column;    
  background-color: white;
  min-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  margin: 0;
  outline: 0;
  z-index: 9002;
  transition: .4s ease-in-out;
  font-family: Nunito, "Nunito", sans-serif;
  overflow-y: auto;
  top: 0;
  left: 0;
  border-radius: 0;
  scrollbar-width: none;
}

.offscreen-left {
  transform: translateX(-100%);
}

#nav-menu-container {
  display: flex;
  width: 100vw;
}
.button {
  cursor: pointer;
}

.nav-menu-header {
  width: 100%;
  margin: 0;
  overflow-y: auto;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #D9D9D9;
  height: 48px;
  min-height: 3rem;
}

.mega-menu-btn {
  font-weight: normal;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: none;
  border-bottom: 1px solid #C9C9C9;
  color: rgb(70, 79, 87) !important;
  cursor: pointer;
}

.mega-menu-btn:hover {
  background-color: #f3f7fb;
}

.menu-footer {
  width: 100%;
  height: 12px;
}

.logo {
  height: 20px;
}

#close-btn {
  background-color: white;
  color: black;
  border: none;
  height: 100%;
  font-size: 1.5em;
  cursor: pointer;
  vertical-align: baseline;
  font-family: revert;
}

.mobile-small-link {
  display: flex;
  align-items: center;
  text-decoration: none !important;
  color: rgb(70, 79, 87) !important;
  font-family: Nunito-ExtraLight, "Nunito Light", Nunito, sans-serif;
  font-size: 14px;
  height: 48px;
  padding-left: 24px;
  width: 100%;
}

.mobile-small-link:hover {
  background-color: #f3f7fb;
}

.arrow-svg {
  height: 1.5em;
}
</style>