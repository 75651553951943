
import { onMounted } from 'vue'
import NavBarItem from './NavBarItem.vue'
import IndividualLearnersMenu from './IndividualLearnersMenu.json'
import LawSchoolsUniversitiesMenu from './LawschoolsUnisMenu.json'
import ResourcesMenu from './ResourcesMenu.json'
import AboutUsMenu from './AboutUsMenu.json'

export default {
  components: {
    NavBarItem
  },
  setup() {
    onMounted(() => {
      window.onclick = function(event: MouseEvent): void {
        const target = event.target as HTMLElement
        if (!target.matches('.drop-button, .drop-menu *, .drop-button *')) {
          clearDropdowns()
        }
      }
    })

    const toggleDropdown = (ele : string) => {
      const dropdown = document.getElementById(ele)
      if (dropdown) {
        const relevantButton = dropdown.previousSibling as HTMLElement
        if (dropdown.classList.contains('show')){
          relevantButton.ariaExpanded = 'false'
          dropdown.ariaHidden = 'true'
          dropdown.classList.remove('show')
        }
        else {
          clearDropdowns()
          relevantButton.ariaExpanded = 'true'
          dropdown.ariaHidden = 'false'
          dropdown.classList.add('show')
        }
      }
    }

    const collapseDropdown = (ele : string) => {
      const dropdown = document.getElementById(ele)
      if (dropdown && dropdown.classList.contains('show')){
        const relevantButton = dropdown.previousSibling as HTMLElement
        relevantButton.ariaExpanded = 'false'
        dropdown.ariaHidden = 'true'
        dropdown.classList.remove('show')
      }
    }

    const expandDropdown = (ele : string) => {
      const dropdown = document.getElementById(ele)
      if (dropdown && dropdown.classList.contains('show')){
        return
      }
      clearDropdowns()
      if (dropdown) {
        const relevantButton = dropdown.previousSibling as HTMLElement
        relevantButton.ariaExpanded = 'true'
        dropdown.ariaHidden = 'false'
        dropdown.classList.add('show')
      }
    }

    const clearDropdowns = () => {
      const dropdowns = document.getElementsByClassName('drop-menu')
      for (const eachDropdown of dropdowns) {
        const openButton = eachDropdown.previousSibling as HTMLElement
        openButton.ariaExpanded = 'false'
        eachDropdown.ariaHidden = 'true'
        eachDropdown.classList.remove('show')
      }
    }

    return {
      toggleDropdown,
      expandDropdown,
      collapseDropdown,
      IndividualLearnersMenu,
      LawSchoolsUniversitiesMenu,
      ResourcesMenu,
      AboutUsMenu
    }
  }
}
