import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3850ce08"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "drop-header"
}
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", {
    id: $props.navBarItem.htmlId,
    class: "drop-menu",
    "aria-hidden": "true"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.navBarItem.contents, (item) => {
      return (_openBlock(), _createElementBlock("li", {
        key: item.label,
        class: "drop-menu-cell"
      }, [
        (!item.url)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(item.label), 1))
          : (_openBlock(), _createElementBlock("a", {
              key: 1,
              href: item.url,
              class: "drop-link"
            }, _toDisplayString(item.label), 9, _hoisted_3))
      ]))
    }), 128))
  ], 8, _hoisted_1))
}