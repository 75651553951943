<template>
  <div
    :id="navBarItem.htmlId + '-mobile'"
    class="mobile-mega-body d-lg-none"
    :class="isOpen ? 'visible' : 'invisible offscreen-right'"
  >
    <div
      class="mobile-mega-header"
    >
      <button
        class="back-button mx-2"
        aria-label="Back to main Nav"
        :aria-controls="navBarItem.htmlId + '-mobile'"
        :aria-expanded="isOpen"
        @click="closeMenu"
      >
        <img
          :src="require('~/img/chevron_right.svg')"
          class="return-arrow"
          alt=""
        >
        {{ navBarItem.title }}
      </button>
      <button
        class="close-button mx-2"
        aria-label="Close Nav"
        @click="closeMega"
      >
        ⨉
      </button>
    </div>
    <ul
      class="mobile-menu"
      aria-hidden="true"
    >
      <li
        v-for="item in navBarItem.contents"
        :key="item.label"
        class="mobile-menu-cell"
      >
        <div 
          v-if="!item.url"
          class="mobile-header"
        >
          {{ item.label }}
        </div>
        <a 
          v-else
          :href="item.url"
          class="mobile-link"
        >
          {{ item.label }}
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    navBarItem: {
      type: Object,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeMega', 'closeMenu'],
  methods: {
    closeMega() {
      this.$emit('closeMega')
    },
    closeMenu() {
      this.$emit('closeMenu')
    }
  }
}
</script>
<style scoped>
.mobile-mega-body {
  list-style: none inside;
  position: absolute;
  z-index: 9003;
  top: 0px;
  left: 0px;
  width: 100%;
  overflow: unset;
  max-height: 100%;
  height: 100%;
  box-shadow: 0 !important;
  border-radius: 0;
  scrollbar-width: none;
  transition: 0.4s ease-in-out;
  background-color: white;
}

.offscreen-right {
  transform: translateX(100%);
}

.mobile-menu {
  list-style: none inside;
  padding: 0 !important;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
}

.mobile-mega-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  min-height: 48px;
  border-bottom: 1px solid rgb(215, 215, 215);
}

.mobile-header {
  margin: 0;
  height: 36px;
  line-height: 36px;
  padding: auto;
  padding-left: 16px;
  color: white;
  background-color: rgba(70, 79, 87, 1);
}

.mobile-link {
  display: flex;
  align-items: center;
  height: 56px;
  margin: 0;
  padding-left: 16px;
  width: 100%;
  color: rgb(70, 79, 87) !important;
  text-decoration: none !important;
  text-wrap: wrap;
}

.mobile-menu-cell {
  border-bottom: 1px solid rgb(215, 215, 215);
}

.mobile-menu-cell:last-child {
  border-bottom: none;
}

.mobile-link:hover {
  background-color: #f4f7fc;
}

.return-arrow {
  height: 1.5em;
  transform: rotate(180deg);
}

.back-button, .close-button {
  vertical-align: middle;
  align-self: middle;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  color: rgb(70, 79, 87);
  text-wrap: nowrap;
}

.back-button {
  display: flex;
  align-items: center;
}

.close-button {
  font-family: arial !important;
  font-size: 24px !important;
}

</style>