<template>
  <div
    class="d-lg-none"
  >
    <button
      id="mobile-logged-in"
      aria-controls="mobile-user-menu"
      :aria-expanded="modelValue"
      class="pe-2 m-2"
      @click="toggleVisibility"
      @keydown.space.exact.prevent="toggleVisibility"
    >
      <img
        :src="require('~/img/account_circle.svg')"
        alt=""
      >
      <br>
      {{ firstName }}
    </button>

    <div
      id="mobile-user-menu"
      :class="modelValue ? 'visible' : 'offscreen-right invisible'"
    >
      <div class="mobile-user-header">
        <div>
          <img
            :src="require('~/img/account_circle.svg')"
            class="ms-3 me-2"
            alt=""
          >
          Hello, {{ firstName }}!
        </div>
        <button 
          class="d-flex close-btn"
          aria-controls="mobile-user-menu"
          :aria-expanded="modelValue"
          @click="toggleVisibility" 
        >
          <div class="h4 me-3">
            ⨉
          </div>
        </button>
      </div>

      <slot />
      <div class="dropdown-tail" />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    firstName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      mobileView: false
    }
  },
  created() {
    this.handleView()
    window.addEventListener('resize', this.handleView)
  },
  methods: {
    toggleVisibility() {
      this.$emit('update:modelValue', !this.modelValue)
    },
    hideDropdown(event) {
      if(!event.currentTarget.contains(event.relatedTarget))
      {
        this.$emit('update:modelValue', false)
      }
    },
    handleView() {
      this.mobileView = window.innerWidth <= 992
    }
  }
}

</script>

<style scoped>

#mobile-user-menu {
  position: fixed;
  left: auto;
  display: block;
  float: right;
  flex-direction: column;    
  background-color: white;
  min-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  margin: 0;
  outline: 0;
  z-index: 9002;
  transition: .4s ease-in-out;
  font-family: Nunito, "Nunito" , sans-serif;
  overflow-y: auto;
  top: 0;
  right: 0;
  border-radius: 0;
  scrollbar-width: none;
}

.mobile-user-header {
  height: 64px;
  width: 100%;
  color: rgb(70, 79, 87);
  display: flex;
  background-color: white;
  align-items: center;
  border-bottom: 1px solid rgb(215, 215, 215);
  justify-content: space-between;
}

.close-btn {
  border: none;
  background-color: white;
}

.offscreen-right {
  transform: translateX(100%);
}

#mobile-logged-in {
  position: absolute;
  color: rgb(70, 79, 87) !important;
  font-size: 14px !important;
  top: 0;
  right: 0;
  border: none;
  background-color: white;
  align-self: middle;
}

@media (max-width: 992px) {
  #dropdown-options-block {
    display: none;
  }
  #navbar-dropdown-btn {
    cursor: default;
  }
}
</style>

