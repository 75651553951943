import { createApp } from 'vue'
import BarbriGlobalNav from '@/components/BarbriGlobalNav'
import '~/fonts/proxima-nova/stylesheet.scss'
import '~/fonts/goldplay/stylesheet.scss'
import '~/fonts/Nunito/stylesheet.scss'

const element = document.querySelector('barbri-global-nav')
if (element instanceof HTMLElement) {
  const dataset = { ...element.dataset }
  for (const key in element.dataset) {
    delete element.dataset[key]
  }
  createApp(BarbriGlobalNav, {
    signInUrl: dataset['signInUrl'],
    firstName: dataset['firstName'],
    lastName: dataset['lastName'],
    logOutUrl: dataset['logOutUrl'],
    bannerWidth: dataset['bannerWidth'],
    dropOptions: JSON.parse(dataset['dropOptions'] ?? '[]'),
    showMenus: dataset['showMenus'] ?? false
  })
  .mount(element)
}
